/* Tribal UI Elements for Last Oasis Theme */

/* Angular Containers */
.tribal-container {
  clip-path: polygon(0 0, 100% 0, 98% 98%, 0 100%);
  @apply bg-charcoal border-2 border-tribal p-4;
}

.tribal-header {
  clip-path: polygon(0 0, 100% 0, 95% 100%, 0 100%);
  @apply bg-sandDark text-sand p-3;
}

/* Tribal Dividers */
.tribal-divider {
  height: 3px;
  background: linear-gradient(90deg, transparent, var(--tribal) 15%, var(--sand) 50%, var(--tribal) 85%, transparent);
  margin: 1.5rem 0;
}

.tribal-divider-vertical {
  width: 3px;
  background: linear-gradient(180deg, transparent, var(--tribal) 15%, var(--sand) 50%, var(--tribal) 85%, transparent);
  margin: 0 1.5rem;
}

/* Tribal Badges */
.tribal-badge {
  clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
  @apply bg-tribal text-sandLight px-3 py-1 inline-block;
}

/* Tribal Progress Bar */
.tribal-progress {
  @apply bg-sandDark overflow-hidden;
  height: 8px;
  clip-path: polygon(0 0, 100% 0, 95% 100%, 0 100%);
}

.tribal-progress-bar {
  @apply bg-tribal h-full;
}

/* Tribal Inputs */
.tribal-input {
  @apply bg-charcoal border-2 border-sandDark text-sandLight p-2;
  clip-path: polygon(0 0, 100% 0, 98% 100%, 0 100%);
  transition: border-color 0.3s;
}

.tribal-input:focus {
  @apply border-tribal outline-none;
}

/* Tribal Select */
.tribal-select {
  @apply bg-charcoal border-2 border-sandDark text-sandLight p-2 pr-8;
  clip-path: polygon(0 0, 100% 0, 95% 100%, 0 100%);
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M2 4L6 8L10 4' stroke='%23D95F32' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
}

/* Tribal Cards */
.tribal-card {
  @apply bg-charcoal border-2 border-tribal overflow-hidden;
  clip-path: polygon(0 0, 100% 0, 98% 98%, 0 100%);
}

.tribal-card-header {
  @apply bg-sandDark text-sand p-3 border-b-2 border-tribal;
  clip-path: polygon(0 0, 100% 0, 98% 100%, 0 100%);
}

.tribal-card-body {
  @apply p-4 text-sandLight;
}

/* Tribal Tabs */
.tribal-tabs {
  @apply flex border-b-2 border-tribal;
}

.tribal-tab {
  @apply px-4 py-2 text-sandLight;
  clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
}

.tribal-tab.active {
  @apply bg-tribal text-sandLight;
}

/* Tribal Tooltips */
.tribal-tooltip {
  @apply bg-charcoal border-2 border-tribal text-sandLight p-2;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 80% 100%, 0 100%);
}

/* Tribal Alerts */
.tribal-alert {
  @apply p-3 border-l-4 border-tribal bg-charcoal text-sandLight;
  clip-path: polygon(0 0, 100% 0, 98% 100%, 0 100%);
}

.tribal-alert-success {
  @apply border-nature;
}

.tribal-alert-warning {
  @apply border-sand;
}

.tribal-alert-danger {
  @apply border-tribal;
}