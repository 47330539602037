@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Okami";
  src: url("../fonts/okami.otf") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: normal;
}

:root {
  --dark: #2E2A26;
  --sand: #C2A57C;
  --sand-light: #E0C9A6;
  --sand-dark: #4A3B31;
  --tribal: #D95F32;
  --nature: #5A7742;
  --charcoal: #1B1B1B;
}

@layer base {
  html {
    @apply min-h-screen min-h-[100dvh] h-full;
  }

  body {
    background-color: var(--dark);
    background-repeat: repeat;
    background-attachment: fixed;
    @apply text-sandLight;
  }

  a {
    @apply text-tribal hover:text-sand transition-colors duration-300;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply text-sand;
  }

  button {
    @apply bg-sandDark hover:bg-tribal text-sandLight transition-colors duration-300;
  }

  input, select, textarea {
    @apply bg-charcoal border-sandDark text-sandLight focus:border-tribal focus:ring-1 focus:ring-tribal;
  }
}

@layer components {
  /* Custom Scrollbar Styling */
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: var(--charcoal);
    border-radius: 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--sand-dark);
    border-radius: 0;
    clip-path: polygon(0 0, 100% 0, 95% 100%, 0 100%);
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--tribal);
  }

  ::-webkit-scrollbar-corner {
    background-color: var(--charcoal);
  }

  /* Firefox scrollbar styling */
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--sand-dark) var(--charcoal);
  }

  /* Card and Modal Styling */
  .card, .modal-content {
    @apply bg-charcoal border-2 border-tribal rounded-none;
    clip-path: polygon(0 0, 100% 0, 98% 98%, 0 100%);
  }

  .card-header,
  .modal-header {
    @apply font-['Okami'] text-2xl text-sand border-b-2 border-tribal py-3 px-4;
    clip-path: polygon(0 0, 100% 0, 98% 100%, 0 100%);
  }

  .card-header .input-group {
    @apply font-['Roboto'] font-['Helvetica_Neue'] font-sans;
  }

  .card-body {
    @apply p-4;
  }

  /* Navigation Styling */
  .navbar {
    @apply bg-sandDark border-b-2 border-tribal;
  }

  .navbar-brand {
    @apply font-['Okami'] font-normal text-sand;
  }

  .navbar-brand span {
    @apply text-2xl !important;
  }

  /* Title Styling */
  .lo-title {
    @apply font-['Okami'] font-medium text-tribal;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  }

  .web-title {
    @apply font-['Okami'];
  }

  /* Button Styling */
  .btn {
    @apply bg-sandDark text-sandLight border-0 transition-colors duration-300;
    clip-path: polygon(0 0, 100% 0, 95% 100%, 0 100%);
  }

  .btn:hover {
    @apply bg-tribal;
  }

  .btn-primary {
    @apply bg-tribal text-sandLight;
  }

  .btn-primary:hover {
    @apply bg-sand;
  }

  /* Table Styling */
  table {
    @apply border-sandDark bg-charcoal text-sandLight;
  }

  th {
    @apply bg-sandDark text-sand border-b border-tribal;
  }

  /* Other Components */
  .tab-content-tree h1 {
    @apply text-2xl !important;
  }

  .tab-content-tree {
    @apply min-h-[200vh];
  }

  .tippy-content h1 {
    @apply text-3xl;
  }

  .tippy-content {
    @apply bg-charcoal border border-tribal text-sandLight;
  }

  .notifications {
    @apply fixed right-0 bottom-0 z-10;
  }

  .notifications .toast {
    @apply opacity-100 bg-charcoal border-l-4 border-tribal;
  }

  .content-v-a {
    content-visibility: auto;
  }
}
