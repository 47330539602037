#map-sidebar {
  z-index: 2000;
  left: -500px;
  transition-property: left;
  transition-duration: 0.5s;
  width: 500px;
  background-color: rgba(46, 42, 38, 0.9);
  border-right: 3px solid #D95F32;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  clip-path: polygon(0 0, 100% 0, 95% 100%, 0 100%);
}

@media only screen and (max-width: 500px) {
  #map-sidebar {
    width: 100%;
    clip-path: none;
  }
}

#map-sidebar.open {
  left: 0px;
}

#toggle-sidebar-button {
  position: absolute;
  left: 500px;
  background-color: #D95F32;
  color: #E0C9A6;
  border: none;
  padding: 8px 12px;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 80% 100%, 0 100%);
  transition: background-color 0.3s;
}

#toggle-sidebar-button:hover {
  background-color: #C2A57C;
}

#map-sidebar.open #toggle-sidebar-button {
  position: relative;
  left: 0;
}
